import React from "react";
import { render } from "react-dom";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import App from "./App";
import HomePage from "./pages/home";

import "./index.css";
import "./assets/fonts/Montserrat-Regular.ttf";

render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route path=":routPath" element={<HomePage />} />
          <Route path="" element={<HomePage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
