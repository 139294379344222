import React from "react";
import "./style.css";

const SearchInput = ({
  searchTerm,
  handleSearchTermChange,
  errorMessage,
  ...props
}) => {
  return (
    <div
      className="search-input-container"
    >
      <input
        {...props}
        className={`search-input`}
        placeholder="SEARCH"
        value={searchTerm}
        onChange={handleSearchTermChange}
      />
      <p className="error-message">{errorMessage}</p>
    </div>
  );
};

export default SearchInput;
