// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-input-container {
  display: flex;
  justify-content: center;
  flex-grow: 1;
  position: relative;
  margin-top: 24px;
  flex-direction: column;
  align-items: center;
}

.search-input-container .search-input {
  width: 75%;
  font-size: 14px;
  color: #555555;
  line-height: 17.07px;
  font-weight: 600;
  height: 60px;
  border-radius: 10px;
  border: none;
  background-color: rgba(255, 255, 255, 0.8);
  padding-left: 30px;
  padding-top: 0;
  padding-bottom: 0;
  outline: none;
}

.search-input-container .search-icon {
  position: absolute;
  right: 25px;
  top: 25px;
}

.search-input-container .search-icon.clear {
  cursor: pointer;
}

.search-input-container .search-input.active {
  background-color: rgba(255, 255, 255, 0.8);
}

@media only screen and (max-width: 480px) {
  .search-input-container .search-input {
    height: 70px;
  }

  .search-input-container .search-icon {
    position: absolute;
    left: unset;
    right: 22px;
    top: 24px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/searchInput/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,YAAY;EACZ,kBAAkB;EAClB,gBAAgB;EAChB,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,UAAU;EACV,eAAe;EACf,cAAc;EACd,oBAAoB;EACpB,gBAAgB;EAChB,YAAY;EACZ,mBAAmB;EACnB,YAAY;EACZ,0CAA0C;EAC1C,kBAAkB;EAClB,cAAc;EACd,iBAAiB;EACjB,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,SAAS;AACX;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,0CAA0C;AAC5C;;AAEA;EACE;IACE,YAAY;EACd;;EAEA;IACE,kBAAkB;IAClB,WAAW;IACX,WAAW;IACX,SAAS;EACX;AACF","sourcesContent":[".search-input-container {\n  display: flex;\n  justify-content: center;\n  flex-grow: 1;\n  position: relative;\n  margin-top: 24px;\n  flex-direction: column;\n  align-items: center;\n}\n\n.search-input-container .search-input {\n  width: 75%;\n  font-size: 14px;\n  color: #555555;\n  line-height: 17.07px;\n  font-weight: 600;\n  height: 60px;\n  border-radius: 10px;\n  border: none;\n  background-color: rgba(255, 255, 255, 0.8);\n  padding-left: 30px;\n  padding-top: 0;\n  padding-bottom: 0;\n  outline: none;\n}\n\n.search-input-container .search-icon {\n  position: absolute;\n  right: 25px;\n  top: 25px;\n}\n\n.search-input-container .search-icon.clear {\n  cursor: pointer;\n}\n\n.search-input-container .search-input.active {\n  background-color: rgba(255, 255, 255, 0.8);\n}\n\n@media only screen and (max-width: 480px) {\n  .search-input-container .search-input {\n    height: 70px;\n  }\n\n  .search-input-container .search-icon {\n    position: absolute;\n    left: unset;\n    right: 22px;\n    top: 24px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
