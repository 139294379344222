import React, {useEffect, useState} from "react";
import "./style.css";
import {Accordion} from "../../accordion";

const SearchList = ({ searchResults, scrollRef }) => {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const handleAccordionClick = (expanded, index) => {
    setExpandedIndex(!expanded ? undefined : index);
  };

  return (
    <div className="section-data-wrapper" ref={scrollRef}>
      {React.Children.toArray(
        searchResults.map((item, index) => (
          <Accordion
            title={item[0]}
            index={index}
            expanded={index === expandedIndex}
            onClick={(e, expanded) => handleAccordionClick(expanded, index)}
            key={index}
            setExpandedIndex={setExpandedIndex}
          >
            <span dangerouslySetInnerHTML={{ __html: item[1] }} />
          </Accordion>
        ))
      )}
    </div>
  );
};

export default SearchList;
