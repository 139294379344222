import React from "react";
import LanguageIconSvg from "../../assets/img/language_icon.svg";
import SignInIcon from "../../assets/img/sign_in_icon.svg";
import MenuIconSvg from "../../assets/img/menu_icon.svg";
import CloseIconSvg from "../../assets/img/close_icon.svg";
import BigGearIconSvg from "../../assets/img/big_gear_icon.svg";
import SmallGearIconSvg from "../../assets/img/small_gear_icon.svg";
import "./style.css";

const Header = () => {
  return (
    <>
      <div className="home-header">
        <a href={`${process.env.REACT_APP_MAIN_DOMAIN}`}>
          <img src="/images/logo-transparenterra-big.svg" alt="" className="logo" />
        </a>
        <div className="home-header-nav-menu">
          <div className="home-header-nav-menu-icon">
            <img src={LanguageIconSvg} alt="" className="language-icon" />
            <span className="home-header-nav-menu-icon-title font-face-mr">
              ENG
            </span>
            <div className="language-icon-to-be-soon-tip">
              <div className="gear-section">
                <img src={BigGearIconSvg} alt="" />
                <img
                  src={SmallGearIconSvg}
                  className="gear-section-small-gear"
                  alt=""
                />
              </div>
              <span className="font-face-mr">To Be Soon</span>
            </div>
          </div>
          <a href={`${process.env.REACT_APP_MAIN_DOMAIN}/sign_in`} className="home-header-nav-menu-icon">
            <img src={SignInIcon} alt="" />
            <span
              className="home-header-nav-menu-icon-title"
            >
              Sign in/up
            </span>
          </a>
          <a href={`${process.env.REACT_APP_MAIN_DOMAIN}/menu`} className="home-header-nav-menu-icon menu">
            <img src={MenuIconSvg} alt="" />
            <span
              className="home-header-nav-menu-icon-title"
            >
              Menu
            </span>
          </a>
          <div className="home-header-nav-menu-icon close">
            <img src={CloseIconSvg} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
