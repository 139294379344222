// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-data-container {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  margin-right: 448px;
}

.search-data-container .search-data-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  cursor: pointer;
}

.search-data-container .search-data-item:last-child {
  margin-bottom: 0;
}

.search-data-container .search-data-item .search-data-title {
  font-size: 35px;
  line-height: 42px;
  font-weight: 800;
  color: rgba(255, 255, 255, 0.6);
  margin-bottom: 3px;
  word-break: break-word;
}

.search-data-container .search-data-item .search-data-title .highlighted {
  color: #ffffff;
}

.search-data-container .search-data-item .search-data-content {
  font-size: 16px;
  line-height: 25px;
  color: #ffffff;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

@media only screen and (max-width: 1200px) {
  .search-data-container {
    margin-right: 0;
  }
}

@media only screen and (max-width: 320px) {
  .search-data-container {
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-right: 0px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/searchPanel/searchList/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,gBAAgB;EAChB,+BAA+B;EAC/B,kBAAkB;EAClB,sBAAsB;AACxB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,cAAc;EACd,gBAAgB;EAChB,gBAAgB;EAChB,uBAAuB;EACvB,oBAAoB;EACpB,qBAAqB,EAAE,4BAA4B;EACnD,aAAa;EACb,4BAA4B;AAC9B;;AAEA;EACE;IACE,eAAe;EACjB;AACF;;AAEA;EACE;IACE,uBAAuB;IACvB,mBAAmB;IACnB,gBAAgB;IAChB,iBAAiB;EACnB;AACF","sourcesContent":[".search-data-container {\n  display: flex;\n  flex-direction: column;\n  margin-top: 40px;\n  margin-right: 448px;\n}\n\n.search-data-container .search-data-item {\n  display: flex;\n  flex-direction: column;\n  margin-bottom: 40px;\n  cursor: pointer;\n}\n\n.search-data-container .search-data-item:last-child {\n  margin-bottom: 0;\n}\n\n.search-data-container .search-data-item .search-data-title {\n  font-size: 35px;\n  line-height: 42px;\n  font-weight: 800;\n  color: rgba(255, 255, 255, 0.6);\n  margin-bottom: 3px;\n  word-break: break-word;\n}\n\n.search-data-container .search-data-item .search-data-title .highlighted {\n  color: #ffffff;\n}\n\n.search-data-container .search-data-item .search-data-content {\n  font-size: 16px;\n  line-height: 25px;\n  color: #ffffff;\n  font-weight: 500;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  display: -webkit-box;\n  -webkit-line-clamp: 1; /* number of lines to show */\n  line-clamp: 1;\n  -webkit-box-orient: vertical;\n}\n\n@media only screen and (max-width: 1200px) {\n  .search-data-container {\n    margin-right: 0;\n  }\n}\n\n@media only screen and (max-width: 320px) {\n  .search-data-container {\n    justify-content: center;\n    align-items: center;\n    margin-top: 20px;\n    margin-right: 0px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
