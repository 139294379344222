import React from "react";

const SectionBuilder = ({ categoriesList, setSelectedCategory, selectedCategory }) => {
  return (
    <div className="section-builder">
      <div className="section-option-wrapper" style={{ flexDirection: "row" }}>
        {React.Children.toArray(
          categoriesList.map((item) => {
            return (
              <button
                className={`section-option ${selectedCategory === item.id ? "active" : ""}`}
                onClick={() => {
                  if (item.id !== selectedCategory) {
                    setSelectedCategory(item.id)
                  } else {
                    setSelectedCategory('')
                  }
                }}
              >
                <span className="check-icon font-face-mr">
                  {item.name}
                </span>
              </button>
            );
          })
        )}
      </div>
    </div>
  );
};

export default SectionBuilder;
