import React, { useEffect, useRef, useState } from "react";
import Header from "../../components/header";
import SearchPanel from "../../components/searchPanel";
import SearchList from "../../components/searchPanel/searchList";
import SectionBuilder from "./sectionBuilder";
import axios from 'axios';
import ScrollUpIconSvg from "../../assets/img/scroll_up_icon.svg";
import MobileScrollUpIconSvg from "../../assets/img/mobile_scroll_up.svg";

const HomePage = () => {
  const scrollRef = useRef(null);
  const [timeoutID, setTimeoutID] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [categoriesList, setCategoriesList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [questionsList, setQuestionsList] = useState([]);
  const [errorMessage, setErrorMessage] = useState([]);
  const [queryFilter, setQueryFilter] = useState('');
  const [scrolled, setScrolled] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 800);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 800);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleVisibility = () => {
    if (window.scrollY > 100) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  useEffect(() => {
    (async () => {
      let url = `${process.env.REACT_APP_QA_API}/help/qa?language=en`;
      if (queryFilter) {
        url += `&q=${queryFilter}`;
      }
      if (selectedCategory) {
        url += `&category_id=${selectedCategory}`;
      }
      await axios.post(url).then((res) => {
        setQuestionsList(res.data.data.doc);
        if (selectedCategory) {
          scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
        }
      }).catch(err => {
        console.log(err.response.data.data);
      });
    })();
  }, [queryFilter, selectedCategory]);

  useEffect(() => {
    (async () => {
      await axios.get(`${process.env.REACT_APP_QA_API}/docs/categories/list?is_active=true`).then((res) => {
        const filteredData = res.data.data.filter((item) => item.parent_id !== null)
        setCategoriesList(filteredData);
      }).catch((err) => {
        console.log(err.response.data.data);
      })
    })();
  }, []);

  const handleSearchChange = (searchWord) => {
    setSearchTerm(searchWord);

    if (timeoutID) {
      clearTimeout(timeoutID);
    }
    if (searchWord.length >= 3 || !searchWord) {
      setErrorMessage('');
      const timeout = setTimeout(() => {
        setQueryFilter(searchWord);
      }, 1000);
      setTimeoutID(timeout);
    } else {
      setErrorMessage("You must write minimun 3 characters")
    }

  };

  return (
    <div className="page-container home">
      {scrolled && (
        <button className="scroll-top-btn" onClick={scrollToTop}>
          <img src={!isSmallScreen ? ScrollUpIconSvg : MobileScrollUpIconSvg} className="arrow"/>
        </button>
      )}
      <video className="video" loop autoPlay muted>
        <source src={"./../../../../video/Islands-2119.mp4"} type="video/mp4"/>
      </video>
      <Header />
      <div className="general-container">
        <div className="primary-container">
          <SearchPanel
            searchTerm={searchTerm}
            onSearchChange={handleSearchChange}
            errorMessage={errorMessage}
          />
          <div className="secondary-container">
            <div className="search-container">
              {categoriesList.length > 0 && (
                <SectionBuilder
                  categoriesList={categoriesList}
                  setSelectedCategory={setSelectedCategory}
                  selectedCategory={selectedCategory}
                />
              )}
              {questionsList && questionsList.length > 0 && (
                <SearchList
                  scrollRef={scrollRef}
                  searchResults={questionsList}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
