import React from "react";
import { Outlet } from "react-router-dom";

import "./style.css";

export default function App(): React.ReactNode {
    return (
        <div className="app">
            <Outlet />
        </div>
    );
}
