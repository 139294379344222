import React, { useState, useEffect } from 'react';
import styles from './Accordion.module.scss';

export const Accordion = (props) => {
  const {
    defaultExpanded,
    index,
    expanded,
    setExpandedIndex,
    children,
    onClick,
    className,
    title,
    ...rest
  } = props;
  const [copiedIndex, setCopiedIndex] = useState(null);

  const handleClick = (event) => {
    onClick?.(event, !expanded);
  };

  useEffect(() => {
    const url = new URL(window.location.href);
    if (url.hash) {
      let urlHash = url.hash.substring(1);
      if (urlHash === titleSlug()) {
        setExpandedIndex(index)
      }
      const scrollElement = document.getElementById(urlHash)
      if (scrollElement) {
        scrollElement.scrollIntoView({
          behavior: 'smooth'
        });
      }
    }
  }, [window.location.href]);

  const onCopy = (e, text, copyIndex) => {
    e.stopPropagation();
    navigator.clipboard.writeText(window.location.origin + `#${text}`)
    setCopiedIndex(copyIndex);

    setTimeout(() => {
      setCopiedIndex(null);
    }, 2000);
  };

  const titleSlug = () => {
    return  title
      .toLowerCase()
      .trim()
      .replace(/[^\w\s-]/g, '')
      .replace(/[\s_-]+/g, '-')
      .replace(/^-+|-+$/g, '');
  }

  return (
    <div
      className={`${styles.accordion} ${className}`}
      onClick={handleClick}
      aria-hidden {...rest}
      id={titleSlug()}
    >
      <div className={styles.header}>
        <div className={styles.headerTitle}>
          <p>{title}</p>
          <div className={styles.tooltipLink}>
            <img src="/images/link_icon.svg" alt="link_icon" onClick={(e) => onCopy(e, titleSlug(), index)}/>
            <span className={styles.tooltipText}>
              <p>{copiedIndex === index ? 'Copied!' : 'Copy link'}</p>
              <img src="/images/link_icon.svg" alt="link_icon" />
            </span>
          </div>
        </div>
        <button>{expanded ? <img src="/images/minusRoundIcon.svg" alt=""/> :
          <img src="/images/plusRoundIcon.svg" alt=""/>}</button>
      </div>
      <div
        className={`${styles.content} ${expanded ? styles.expanded : ''}`}
      >
        <p className={styles.text}>
          {children}
        </p>
      </div>
    </div>
  );
};
