import React from "react";

import SearchInput from "../searchInput";

const SearchPanel = ({
 searchTerm,
 onSearchChange,
 errorMessage
}) => {
  const handleSearchTermChange = (event) => {
    const searchWord = event.target.value || "";
    onSearchChange(searchWord);
  };

  return (
    <SearchInput
      errorMessage={errorMessage}
      searchTerm={searchTerm}
      handleSearchTermChange={handleSearchTermChange}
    />
  );
};

export default SearchPanel;
